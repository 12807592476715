import { forwardRef, MutableRefObject, useEffect, useImperativeHandle, useRef, useState } from "react"
import { twMerge } from "tailwind-merge"
import Vimeo from "@vimeo/player"
import { Button } from "@components/atoms/Button"

export type VideoPlayerType = HTMLDivElement & {
  player: MutableRefObject<Vimeo | null>
  open: () => void
  close: () => void
}

export const VideoPlayer = forwardRef<
  VideoPlayerType,
  { videoUrl: string; className?: string; onTimeUpdate?: (v: Vimeo | null) => void }
>(({ videoUrl, className, onTimeUpdate }, ref) => {
  const container = useRef<HTMLDivElement>(null!)
  const [shown, setShown] = useState(false)
  const playerRef = useRef<HTMLDivElement>(null)
  const player = useRef<Vimeo | null>(null)

  useImperativeHandle(
    ref,
    () =>
      Object.assign(container.current!, {
        player,
        open,
        close,
      }),
    [container],
  )

  const open = async () => {
    setShown(true)
    await player.current?.play()
    player.current?.setCurrentTime(0)
  }

  const close = () => {
    setShown(false)
    player.current?.pause()
  }

  useEffect(() => {
    if (!playerRef.current) return

    player.current = new Vimeo(playerRef.current, {
      url: videoUrl,
      autoplay: false,
      loop: false,
      byline: false,
      // @ts-ignore
      colors: ["222222", "EBBE32", "EBBE32", "222222"],
      portrait: false,
      responsive: true,
      title: false,
      vimeo_logo: false,
      pip: false,
    })

    player.current.on("timeupdate", () => {
      if (onTimeUpdate) {
        onTimeUpdate(player.current)
      }
    })

    player.current.on("ended", () => {
      setShown(false)
    })

    return () => {
      player.current?.pause()
      player.current?.off("timeupdate")
      player.current?.off("ended")
      player.current?.destroy()
    }
  }, [])

  // useEffect(() => {
  //   player.current?.loadVideo(videoUrl)
  // }, [videoUrl])

  return (
    <div
      ref={container}
      className={twMerge(
        "fixed top-0 left-0 w-full h-full bg-black bg-opacity-90 transition-opacity opacity-0 pointer-events-none flex justify-center items-center z-[200]",
        shown ? "opacity-100 pointer-events-auto" : "",
        className,
      )}
    >
      <div className="w-[95vw] md:w-2/3">
        <div className="w-full h-0 overflow-hidden pt-[56.25%] bg-black relative">
          <div className="absolute top-0 left-0 w-full h-full">
            <div ref={playerRef} />
          </div>
        </div>
      </div>
      <Button className="absolute right-4 top-4 bg-white hover:bg-beige-dark" onClick={() => close()}>
        <svg style={{ width: "1em", height: "1em" }} viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M4.46796 6.35356C4.66322 6.1583 4.66322 5.84172 4.46796 5.64645L0.76432 1.94282C0.569058 1.74755 0.569058 1.43097 0.76432 1.23571L1.23572 0.764305C1.43099 0.569043 1.74757 0.569043 1.94283 0.764305L5.64647 4.46794C5.84173 4.6632 6.15831 4.6632 6.35358 4.46794L10.0572 0.764305C10.2525 0.569043 10.5691 0.569042 10.7643 0.764305L11.2357 1.23571C11.431 1.43097 11.431 1.74755 11.2357 1.94282L7.53209 5.64645C7.33682 5.84172 7.33682 6.1583 7.53209 6.35356L11.2357 10.0572C11.431 10.2525 11.431 10.569 11.2357 10.7643L10.7643 11.2357C10.5691 11.431 10.2525 11.431 10.0572 11.2357L6.35358 7.53207C6.15831 7.33681 5.84173 7.33681 5.64647 7.53207L1.94283 11.2357C1.74757 11.431 1.43099 11.431 1.23572 11.2357L0.76432 10.7643C0.569058 10.569 0.569058 10.2525 0.76432 10.0572L4.46796 6.35356Z"
            fill="currentColor"
          />
        </svg>
      </Button>
    </div>
  )
})
