import { BlockItem } from "@components/atoms/BlockItem"
import { BlockWrapper } from "@components/atoms/BlockWrapper"
import { useGSAP } from "@gsap/react"
import { defineBlock, EditableText, useBlockContext } from "eddev/blocks"
import gsap from "gsap"
import { ScrollTrigger } from "gsap/dist/ScrollTrigger"
import { useEffect, useRef, useState } from "react"
import { useWindowSize } from "react-use"

export const meta: BlockMeta = {
  title: "Home Rankings Item",
}

const ARROW_VARIATIONS = [
  [
    { x: 8.5, y: 7.75 },
    { x: 71.25, y: 70.25 },
    { x: 99, y: 43 },
    { x: 128, y: 69 },
    { x: 149.25, y: 49.25 },
    { x: 197, y: 96 },
    { x: 212.5, y: 82.25 },
    { x: 273.25, y: 141.5 },
  ],
  [
    { x: 8.5, y: 7.75 },
    { x: 40, y: 30 },
    { x: 85, y: 50 },
    { x: 120, y: 90 },
    { x: 155, y: 20 },
    { x: 175, y: 80 },
    { x: 205, y: 60 },
    { x: 273.25, y: 141.5 },
  ],
  [
    { x: 8.5, y: 7.75 },
    { x: 50, y: 40 },
    { x: 95, y: 10 },
    { x: 115, y: 75 },
    { x: 140, y: 60 },
    { x: 190, y: 85 },
    { x: 225, y: 70 },
    { x: 273.25, y: 141.5 },
  ],
  [
    { x: 8.5, y: 7.75 },
    { x: 20, y: 25 },
    { x: 110, y: 55 },
    { x: 135, y: 30 },
    { x: 160, y: 95 },
    { x: 180, y: 40 },
    { x: 220, y: 100 },
    { x: 273.25, y: 141.5 },
  ],
  [
    { x: 8.5, y: 7.75 },
    { x: 35, y: 20 },
    { x: 75, y: 65 },
    { x: 130, y: 85 },
    { x: 145, y: 45 },
    { x: 160, y: 95 },
    { x: 210, y: 50 },
    { x: 273.25, y: 141.5 },
  ],
]

const Arrow = ({ index, start }: { index: number; start?: boolean }) => {
  const canvasRef = useRef<HTMLCanvasElement>(null)
  const res = 3
  const padding = 20
  const lineWidth = 15 * res
  const points = useRef(ARROW_VARIATIONS[Math.floor(Math.random() * ARROW_VARIATIONS.length)])
  const segments = useRef(
    (() => {
      // create line segements from array of points
      const segments = []
      for (let i = 0; i < points.current.length - 1; i++) {
        // add extra line width to each segment
        segments.push([points.current[i], points.current[i + 1]])
      }
      return segments
    })(),
  )
  const animate = useRef(segments.current.map(() => ({ x: 0, y: 0 })))
  const arrowAngle = useRef(0)
  const tl = useRef<gsap.core.Timeline>()
  const { width, height } = useWindowSize()
  const playTimer = useRef<number>()

  const setDimensions = () => {
    if (!canvasRef.current) return
    canvasRef.current.width = (285 + padding * 2) * res
    canvasRef.current.height = (154 + padding * 2) * res
  }

  const draw = () => {
    const canvas = canvasRef.current
    if (!canvas) return

    const ctx = canvas.getContext("2d")
    if (!ctx) return

    setDimensions()

    ctx.clearRect(0, 0, canvas.width, canvas.height)
    ctx.strokeStyle = "#D14241"
    ctx.lineWidth = lineWidth
    ctx.lineCap = "butt"

    ctx.beginPath()
    let lastSegment = 0
    segments.current.forEach((segment, i) => {
      const [start] = segment
      const { x, y } = animate.current[i]
      if (start.x !== x || start.y !== y) {
        if (i === 0) {
          ctx.moveTo((start.x + padding) * res, (start.y + padding) * res)
        }
        ctx.lineTo((x + padding) * res, (y + padding) * res)
        lastSegment = i
      }
    })
    ctx.stroke()

    // draw arrow head at end of last segment
    const [start] = segments.current[lastSegment]
    const end = animate.current[lastSegment]
    const sx = start.x + padding
    const sy = start.y + padding
    const ex = end.x + padding
    const ey = end.y + padding
    const dx = ex - sx
    const dy = ey - sy
    arrowAngle.current += (Math.atan2(dy, dx) - arrowAngle.current) * 0.25
    const headLength = 30 * res
    ctx.lineCap = "square"
    ctx.beginPath()
    ctx.moveTo(ex * res, ey * res)
    ctx.lineTo(
      ex * res - headLength * Math.cos(arrowAngle.current - Math.PI / 4),
      ey * res - headLength * Math.sin(arrowAngle.current - Math.PI / 4),
    )
    ctx.moveTo(ex * res, ey * res)
    ctx.lineTo(
      ex * res - headLength * Math.cos(arrowAngle.current + Math.PI / 4),
      ey * res - headLength * Math.sin(arrowAngle.current + Math.PI / 4),
    )
    ctx.stroke()
  }

  useGSAP(
    () => {
      tl.current = gsap.timeline({ paused: true, onUpdate: draw })
      segments.current.forEach((segment, i) => {
        const [start, end] = segment
        tl.current?.add(
          gsap.fromTo(
            animate.current[i],
            {
              x: start.x,
              y: start.y,
            },
            {
              x: end.x,
              y: end.y,
              duration: 0.3,
              ease: "power1.inOut",
            },
          ),
        )
      })
    },
    { scope: canvasRef },
  )

  useEffect(() => {
    draw()
  }, [width, height])

  useEffect(() => {
    if (playTimer.current) {
      clearTimeout(playTimer.current)
    }
    tl.current?.pause()
    if (start) {
      playTimer.current = setTimeout(() => {
        tl.current?.restart()
      }, 200 * index)
    }
  }, [start])

  return <canvas className="w-full h-auto" ref={canvasRef} />
}

export default defineBlock("home/rankings-item", (props) => {
  const bctx = useBlockContext()
  const [started, setStarted] = useState(false)

  const shouldStartArrow = (trigger: ScrollTrigger) => {
    const play = trigger.progress > 0 && trigger.progress < 1
    setStarted(play)
  }

  return (
    <BlockWrapper
      className="w-[70vw] shrink-0 snap-start md:w-1/3 md:shrink"
      ignoreToolbar
      onTrigger={shouldStartArrow}
      triggerStart="top bottom-=15%"
      triggerEnd="bottom top"
    >
      <BlockItem className="w-full bg-beige h-[65vw] md:h-auto">
        <div className="flex flex-col items-center gap-3 md:gap-4">
          <EditableText
            as="p"
            className="block type-label-m md:type-label-l"
            store="title"
            inlineToolbar={false}
            defaultValue="Number of mince pies per $100"
            placeholder="Number of mince pies per $100"
          />
          <EditableText
            as="p"
            className="inline-block type-label-s rounded-lg px-3 py-1 w-fit bg-beige-dark"
            store="daterange"
            inlineToolbar={false}
            defaultValue="1982 - 2015"
            placeholder="1982 - 2015"
          />
          <div className="w-rankArrow flex justify-center items-center mt-8 mb-6 text-red">
            <Arrow index={bctx?.index || 0} start={started} />
          </div>
        </div>
      </BlockItem>
    </BlockWrapper>
  )
})
