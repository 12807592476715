import { BlockItem } from "@components/atoms/BlockItem"
import { BlockWrapper } from "@components/atoms/BlockWrapper"
import { Button } from "@components/atoms/Button"
import { ResponsiveImage } from "@components/atoms/ResponsiveImage"
import { VideoPlayer, VideoPlayerType } from "@components/site/VideoPlayer"
import { IconPlay, IconTrophy } from "@components/svg/Icons"
import { useGSAP } from "@gsap/react"
import { defineBlock, EditableText } from "eddev/blocks"
import gsap from "gsap"
import { useRef } from "react"

export const meta: BlockMeta = {
  title: "Home Hero",
}

export default defineBlock("home/hero", (props) => {
  const innerRef = useRef<HTMLDivElement>(null)
  const logoRef = useRef<HTMLDivElement>(null)
  const player = useRef<VideoPlayerType>(null)

  useGSAP(
    () => {
      if (!env.client) return
      const tl = gsap.timeline()
      tl.fromTo(
        ".hero-logo-main",
        { scale: 1.2, opacity: 0 },
        { scale: 1, opacity: 1, ease: "power2.out", duration: 2 },
        1,
      ).fromTo(
        ".completed-promo",
        { x: "100%", opacity: 0 },
        { x: 0, opacity: 1, duration: 0.75, ease: "power2.out" },
        1.5,
      )
    },
    { scope: innerRef },
  )

  return (
    <>
      <BlockWrapper
        toolbarState="live-index"
        label="hero"
        className="relative h-[calc(89vh-var(--size-toolbarHeight)-var(--spacing-2))] md:h-[calc(100vh-var(--size-toolbarHeight)-var(--spacing-2))] flex justify-center items-center overflow-hidden"
      >
        <BlockItem className="!static">
          <div ref={innerRef} className="flex justify-center items-center w-full">
            {props.video?.mediaItemUrl && (
              <video
                src={props.video.mediaItemUrl}
                className="absolute w-full h-full left-0 top-0 object-cover block"
                loop
                muted
                playsInline
                autoPlay
              />
            )}

            <div ref={logoRef} className="hero-logo-main w-heroLogo mx-auto relative mt-9 md:mt-6">
              <img
                src={`${env.themePath}assets/images/home/logo-officially.png`}
                className="w-full h-auto block pointer-events-none"
                alt="New Zealand - Officially - The Best Place in the World to Have Herpes"
              />
            </div>

            <div
              className="completed-promo group absolute bottom-4 right-4 bg-white rounded-sm flex justify-center items-center gap-3 md:gap-2 p-3 md:p-2 text-black cursor-pointer transition-colors duration-300 md:hover:bg-orange"
              onClick={() => player.current?.open()}
            >
              <div className="w-completeTileThumbW h-completeTileH type-body-tiny flex flex-col justify-between">
                <EditableText
                  as="p"
                  store="completeTitle"
                  defaultValue="New Zealand, we made it to number one!"
                  inlineToolbar={false}
                  placeholder="New Zealand, we made it to number one!"
                />
                <IconTrophy className="w-[1.5em] h-[1.5em] md:m-1 md:w-[2em] md:h-[2em]" />
              </div>
              <div className="w-completeTileThumbW h-completeTileH relative flex items-center justify-center">
                <video
                  src={`${env.themePath}assets/videos/home/complete-loop.mp4`}
                  className="absolute left-0 top-0 w-full h-full object-cover block rounded-tiny overflow-hidden"
                  loop
                  muted
                  playsInline
                  autoPlay
                />
                <Button
                  icon={<IconPlay style={{ width: "1.5em", height: "1.5em" }} />}
                  className="w-fit pointer-events-none relative !px-2"
                />
              </div>
            </div>
          </div>
        </BlockItem>
      </BlockWrapper>
      <VideoPlayer ref={player} videoUrl="https://player.vimeo.com/video/1015091040?h=d585761e4c" />
    </>
  )
})
