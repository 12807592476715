import { Link } from "eddev/routing"
import { twMerge } from "tailwind-merge"

export const Button = ({
  children,
  icon,
  reverse,
  className,
  large,
  onClick,
  href,
}: {
  children?: React.ReactNode
  icon?: React.ReactNode
  reverse?: boolean
  className?: string
  large?: boolean
  onClick?: () => void
  href?: string
}) => {
  const Element = href ? Link : "button"
  const props = href ? { href } : {}

  return (
    <Element
      {...props}
      className={twMerge(
        "flex px-3 h-button cursor-pointer rounded-md text-black type-label-m bg-orange md:type-label-l transition-colors duration-300",
        large ? "h-buttonLg" : "",
        className,
      )}
      onClick={onClick}
    >
      <div className={twMerge("flex gap-2 justify-between items-center h-full", reverse ? "flex-row-reverse" : "")}>
        {icon && <div className="md:text-[1.3em]">{icon}</div>}
        {children && <div className={twMerge("px-2 inline-block", icon && !reverse ? "md:pl-0" : "")}>{children}</div>}
      </div>
    </Element>
  )
}
